<template>
    <div class="continue-review">
        <div class="header-row">
            <div class="title-text">Email registered to UXfolio</div>
            <div class="subtitle-text">It looks like you have a UXfolio account. Log in and use it for this review.</div>
        </div>
        <div class="button-container row">
            <CtaButton type="secondary" dark-scheme @click="switchModalState">Try another email</CtaButton>
            <CtaButton type="primary" dark-scheme @click="loginWithUXfolio">Log in to UXFolio</CtaButton>
        </div>
    </div>
</template>

<script>
import CtaButton from "../../../../common/components/CtaButton.vue";
import {ReviewLoginModalStateEnum} from "@/scripts/common/enums.js";
import {event} from "vue-gtag";

export default {
    name: "AuthErrorLoginModal",
    props:[ 'setLoginModalState' ],
    components: {
        CtaButton,
    },
    methods: {
        loginWithUXfolio() {
            //GA Event
            event('Review log-in with UXFolo account', {
                'event_category': 'Review mode',
            });

            const host = window.location.host;
            const path = "/review/login?host=" + host + "&path=" + window.location.pathname;
            const encodedUrl = `/loginredirect?host=${encodeURIComponent(host)}&redirectTo=${encodeURIComponent(path)}`;
            window.location = encodedUrl;
        },
        switchModalState() {
            this.setLoginModalState(ReviewLoginModalStateEnum.GUEST);
        }
    },
    computed: {
        loginDetails() {
            return this.$reviewStore.loginDetails
        }
    }
}
</script>

<style scoped lang="scss">
    .continue-review {
        display: flex;
        gap: 32px;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .header-row {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;

            .title-text {
                font: var(--editor-ui-h2);
                color: var(--asphalt10);
            }

            .subtitle-text {
                text-align: center;
                font: var(--editor-ui-b1-regular);
                color: var(--asphalt30);
            }
        }
    }
</style>