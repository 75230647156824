<template>
    <div class="overview-input-group">
        <div class="title-container">
            <Icon :icon-name="icon" />
            <p>{{ title }}</p>
        </div>
        <div v-if="editor && isInput" class="input-text-box" :class="[{focused:focused}]">
            <EditorContent :editor="editor"
                           :class="'ufo--text-area-review-overview'"
            />
            <div class="editor-toolbar">
                <div class="buttons">
                    <span @click="editor.chain().focus().toggleBold().run()" :class="{active:editor.isActive('bold')}"><Icon iconName="text-bold" /></span>
                    <span @click="editor.chain().focus().toggleItalic().run()" :class="{active:editor.isActive('italic')}"><Icon iconName="text-italic" /></span>
                    <span @click="editor.chain().focus().toggleOrderedList().run()" :class="{active:editor.isActive('orderedList')}"><Icon iconName="text-ol" /></span>
                    <span @click="editor.chain().focus().toggleBulletList().run()" :class="{active:editor.isActive('bulletList')}"><Icon iconName="text-ul" /></span>
                </div>
                <div v-if="saved" class="save-feedback-container">
                    <Icon v-if="!saving" :icon-name="'check'" />
                    <img v-else src="../../../../images/saving-oval.png" width="16" height="16" />
                    <span>Saved</span>
                </div>
            </div>
        </div>
        <div class="overview-text" v-else-if="!isInput && aspectDone" v-html="overviewText" />
        <ReviewResultEmptyState v-else :title="title" :button-text="'Add ' + title" :handle-click="closeAndOpenOverview"/>
    </div>
</template>

<script>
    import { Editor, EditorContent } from "@tiptap/vue-3";
    import StarterKit from "@tiptap/starter-kit";
    import Placeholder from "@tiptap/extension-placeholder";
    import Icon from '../../../common/Icon.vue';
    import ApiHelper from "../../../api/ApiHelper.js";
    import ReviewResultEmptyState from "@/scripts/view/review/components/ReviewResultEmptyState.vue";
    import {reviewStore} from "@/scripts/store/modules/reviewStore.js";

    export default {
        name: "OverviewInputBox",
        props:['title', 'icon', 'placeholder', 'fieldToUpdate', 'isInput'],
        components: {
            ReviewResultEmptyState,
            EditorContent,
            Icon
        },
        data() {
            return {
                text:'',
                typeInterval:null,
                inactivityInterval:null,

                //editor related
                editor:null,
                focused:false,
                //save feedback
                saved:false,
                saving:false
            }
        },
        updated() {
            console.log('overviwfield updates')
        },
        mounted() {
            if(this.$reviewStore.review.overview[this.fieldToUpdate]) {
                this.text = this.$reviewStore.review.overview[this.fieldToUpdate];
                this.saved = true;
            }

            if(this.isInput) {
                this.editor = new Editor({
                    content: this.text,
                    extensions: [
                        StarterKit,
                        Placeholder.configure({
                            placeholder: this.placeholder,
                            showOnlyWhenEditable: true,
                            showOnlyCurrent: true
                        }),
                    ],
                    onUpdate: ({ getJSON, getHTML }) => {
                        this.applyTextEdit(this.editor.getHTML(), this.editor.getText());
                        if(this.typeInterval) {
                            clearInterval(this.typeInterval);
                            this.typeInterval = null;
                        }

                        this.typeInterval = setInterval(() => {
                            this.autoSave();
                        }, 3000);
                    },
                    onBlur: () => {
                        this.focused = false;
                        this.clearAllIntervals();
                        this.autoSave();
                    },
                    onFocus: () => {
                        this.focused = true;
                        this.checkReviewerUser();
                    }
                })
            }
        },
        methods: {
            clearAllIntervals() {
                clearInterval(this.typeInterval);
                this.typeInterval = null;
                clearInterval(this.inactivityInterval);
                this.inactivityInterval = null;
            },
            applyTextEdit(newTextHtml, newText) {
                this.$reviewStore.review.overview[this.fieldToUpdate] = newText.length ? newTextHtml : null;
            },
            autoSave() {
                if(this.reviewer) {
                    this.saving = true;
                    this.saveReview();
                    clearInterval(this.typeInterval);
                    this.typeInterval = null;
                }
            },
            checkReviewerUser() {
                if(!this.reviewer) {
                    this.$reviewStore.showReviewLoginModal = true;
                }
            },
            async saveReview() {
                try {
                    await ApiHelper.post('/api/review/' + this.$reviewStore.review._id + "/" + this.$reviewStore.review.contentId, this.$reviewStore.review);
                    this.saved = true;
                    this.saving = false;
                }
                catch(err) {
                    console.log('Failed to save review', err)
                }
            },
            htmlToString(text) {
                return text.replaceAll("<p>", "")
                    .replaceAll("</p>","")
                    .replaceAll("<ul>", "")
                    .replaceAll("</ul>", "")
                    .replaceAll("<li>", "")
                    .replaceAll("</li>", "")
            },
            closeAndOpenOverview() {
                this.$reviewStore.showOverviewModal = false;
                this.$reviewStore.showGeneralOverview = true;
            }
        },
        computed: {
            overviewText(){
                return this.$reviewStore.review.overview[this.fieldToUpdate]
            },
            reviewer() {
                return this.$reviewStore.review.reviewerDetails;
            },
            aspectDone() {
                return this.$reviewStore.review.overview[this.fieldToUpdate] != null && this.htmlToString(this.$reviewStore.review.overview[this.fieldToUpdate]).length >= 1
            }
        }
    }
</script>

<style lang="scss">
.ufo--text-area-review-overview,
.ufo--text-area-comment
{
    .ProseMirror {
        font: var(--editor-ui-b2-regular);
        color: var(--asphalt20);
        width: 100%;
        height: 100%;

        p {
            margin-bottom: 1px;
        }
    }

    .ProseMirror-focused {
        border: none;
        outline: none;
        p.is-editor-empty {
            &:first-child::before {
                color: var(--asphalt60) !important;
            }
        }
    }

    p.is-editor-empty {
        &:first-child::before {
            content: attr(data-placeholder);
            float: left;
            color: var(--asphalt50);
            pointer-events: none;
            height: 0;
        }
    }
}
</style>

<style scoped lang="scss">
    .overview-input-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        flex: 1 0 0;
        align-self: stretch;

        .title-container {
            display: flex;
            align-items: center;
            gap: 12px;
            align-self: stretch;

            p {
                &::first-letter {
                    text-transform: uppercase; /* Capitalize only the first letter */
                }
                margin: 0;
                font: var(--editor-ui-b1-medium);
                color: var(--asphalt20);
            }
        }

        .input-text-box {
            border-radius: 6px;
            border: 1px solid var(--asphalt80);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1 0 0;
            align-self: stretch;

            .ufo--text-area-review-overview {
                display: flex;
                padding: 16px;
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;
                flex: 1 0 0;
                align-self: stretch;
                overflow: auto;

                ::-webkit-scrollbar-track {
                    background-color: transparent; /* Makes the track transparent */
                }

                scrollbar-color: #888 transparent; /* Thumb color and track color */
            }

            &.focused {
                border: 1px solid var(--asphalt60);
            }

            .editor-toolbar {
                display: flex;
                padding: 10px 16px;
                justify-content: space-between;
                align-items: center;
                align-self: stretch;
                border-top: 1px solid var(--asphalt80);

                .buttons {
                    display: flex;
                    align-items: center;
                    gap: 6px;

                    span {
                        width: 24px;
                        height: 24px;
                        padding: 2px 3px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 4px;

                        svg {
                            color: var(--asphalt40);
                            width:20px;
                            height:20px;
                        }

                        &.active {
                            background-color: var(--asphalt70);
                            svg {
                                color: var(--asphalt10);
                            }
                        }

                        &:hover {
                            cursor: pointer;
                            svg {
                                color: var(--asphalt10);
                            }
                        }
                    }
                }
                .save-feedback-container {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    svg {
                        width: 16px;
                        height: 16px;
                        color: var(--asphalt40);
                    }

                    span {
                        color: var(--asphalt40);
                        font: var(--editor-ui-l-regular);
                        letter-spacing: 0.12px;
                    }
                }
            }
        }

        .overview-text {
            width: 100%;
            font: var(--editor-ui-b2-regular);
            color: var(--asphalt30);
        }
    }
</style>