<!-- Containers maybe shit -->
<template>
    <div v-if="project.type === 'caseStudy' &&
                portfolio.settings.showRelatedCaseStudiesOnProjects &&
                footerProjects.length"
         class="footer-section ufo-sec related-case-studies"
         :style="[{'--section-padding-top' : portfolio.portfolioFooter.style && portfolio.portfolioFooter.style.paddingTop ? portfolio.portfolioFooter.style.paddingTop + 'px' : null }]"
    >
        <ToggleGrid />
        <div class="grid">
            <div class="grid-container">
                <div class="related-projects-container">
                    <AnimationWrapper
                        :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                        :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                    >
                        <template #animationContent>
                            <h3 class="title">Read more of my case studies</h3>
                        </template>
                    </AnimationWrapper>
                    <div class="related-projects">
                        <div v-bind:key="footerProject" v-for="(footerProject, index) in footerProjects.slice(0, 4)" @click="navigateToProjectFooter(portfolio.ownDomainAddress ? portfolio.ownDomainAddress : '', portfolio.shortId, footerProject.shortId)" class="related-project">
                            <AnimationWrapper
                                :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                                :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                                :delay-animation="index ? index * 0.1 : 0"
                            >
                                <template #animationContent>
                                    <div class="image" :style="{height: '158px', width: '158px'}">
                                        <ProjectThumbnail v-if="footerProject.settings.usingGeneratedThumbnail" :caseStudy="footerProject" force-squared />
                                        <img v-else-if="footerProject.image.url"
                                             loading="lazy"
                                             width="158"
                                             height="158"
                                             :src="footerProject.image.croppedImageUrl ? footerProject.image.croppedImageUrl : footerProject.image.url"
                                             :alt="footerProject.name">
                                        <img v-else-if="footerProject.sections[0].type === 'MainHeader' && footerProject.sections[0].image && footerProject.sections[0].image.url" loading="lazy" width="158" height="158" :src="footerProject.sections[0].image.croppedImageUrl ? footerProject.sections[0].image.croppedImageUrl : footerProject.sections[0].image.url" :alt="footerProject.name">
                                        <img v-else-if="!footerProject.sections[0].backgroundColor" loading="lazy" width="158" height="158" src="/example_project_images/v2/empty-project-thumbnail.jpg" :alt="footerProject.name">
                                    </div>
                                </template>
                            </AnimationWrapper>
                            <AnimationWrapper
                                :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                                :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                                :delay-animation="index ? index * 0.1 : 0"
                            >
                                <template #animationContent>
                                    <div class="related-project-title">
                                        <p>{{footerProject.name}}</p>
                                    </div>
                                </template>
                            </AnimationWrapper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-section ufo-sec"
        :style="[{'--section-padding-top': portfolio.portfolioFooter.style && portfolio.portfolioFooter.style.paddingTop ?portfolio.portfolioFooter.style.paddingTop + 'px' : null }]"
    >
        <ToggleGrid />
        <div class="grid">
            <div class="grid-container">
                <div v-if="!notAllowedInThemes.includes(portfolioTemplate) && portfolio.templateSettings.logo && portfolio.portfolioFooter.displayLogo && portfolio.portfolioFooter.displayText"
                     class="logo-container"
                     :class="portfolio.portfolioFooter.text && portfolio.portfolioFooter.text.style && portfolio.portfolioFooter.text.style.textAlign ? portfolio.portfolioFooter.text.style.textAlign : 'left'">
                    <AnimationWrapper
                        :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                        :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                    >
                        <template #animationContent>
                            <a :href="homeLink" class="footer-logo">
                                <img :src="portfolio.templateSettings.logo.image.url" :height="portfolio.templateSettings.logo.image.height" alt="Portfolio logo">
                            </a>
                        </template>
                    </AnimationWrapper>
                </div>
                <SectionText v-if="portfolio.portfolioFooter.displayText && portfolio.portfolioFooter.text && portfolio.portfolioFooter.text.text" :section-text="portfolio.portfolioFooter.text" />

                <div v-if="portfolio.portfolioFooter.displayText"
                     class="button-container"
                     :class="portfolio.portfolioFooter.text && portfolio.portfolioFooter.text.style && portfolio.portfolioFooter.text.style.textAlign ? portfolio.portfolioFooter.text.style.textAlign : 'left'">
                    <CtaButton :section="portfolio.portfolioFooter" :form-submit-button="false" />
                </div>

                <div v-if="portfolio.templateSettings.footerSettings.showLine" class="separator">
                    <AnimationWrapper
                        :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                        :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                        :is-final-section="true"
                    >
                        <template #animationContent>
                            <div class="line"
                            :class="portfolio.templateSettings.dividerLineSettings ? portfolio.templateSettings.dividerLineSettings.weight : ''">
                            </div>
                        </template>
                    </AnimationWrapper>
                </div>
                <div class="footer-links" :class="{'profile_theme_default' : !$store.state.portfolio.templateSettings.theme || $store.state.portfolio.templateSettings.theme.includes('theme_default')}">
                    <div class="link-container">
                        <div v-if="portfolio.portfolioFooter.displayPageLinks && portfolioPages || !portfolio.socialLinks"
                             class="portfolio-pages"
                             :class="portfolio.portfolioFooter.displaySocialLinks && portfolio.socialLinks ? 'portfolio-pages--left' : ''"
                        >
                            <div v-bind:key="page" v-for="(page, index) in portfolioPages" class="navbar-item" :data-text="page.name">
                                <AnimationWrapper
                                    :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                                    :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                                    :is-final-section="true"
                                    :delay-animation="index ? index * 0.1 : 0"
                                >
                                    <template #animationContent>
                                        <!-- TODO please use a nav item component here or create something else -->
                                        <a v-if="page.type === 'home'" @click="homeNavigation" class="btn">{{ transformText(page.name) }}</a>
                                        <a v-if="page.type === 'about' || page.type === 'contact'" @click="(evt) => navigationRef(evt, page)" class="btn">{{ transformText(page.name) }}</a>
                                        <a v-if="page.type === 'file' || page.type === 'link' || page.type === 'mailto'" :href="page.type === 'mailto' ? 'mailto:' + page.settings.link.url : page.settings.link.url" :target="page.settings.link.openInNewTab? '_blank' : ''" rel="nofollow noopener" class="btn">{{ page.name ? transformText(page.name) : '' }}</a>
                                        <a v-if="page.type === 'custom'" @click="(evt) => navigationRef(evt, page)" rel="nofollow noopener" class="nav-item" >{{ transformText(page.name) }}</a>
                                        <a v-if="page.type === 'blank'" @click="(evt) => navigationRef(evt, page)" class="btn">{{ transformText(page.name) }}</a>
                                    </template>
                                </AnimationWrapper>
                            </div>
                        </div>
                        <div v-if="portfolio.portfolioFooter.displaySocialLinks && portfolio.socialLinks" class="social-links" :class="portfolio.portfolioFooter.displayPageLinks && portfolioPages ? 'social-links--right' : ''" >
                            <div v-bind:key="socialLink" v-for="(socialLink, index) in portfolio.socialLinks" class="footer-link">
                                <AnimationWrapper
                                    :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                                    :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                                    :is-final-section="true"
                                    :delay-animation="index ? index * 0.1 : 0"
                                >
                                    <template #animationContent>
                                        <a v-if="socialLink.url !== ''" :href="socialLink.url" target="_blank" rel="nofollow noopener" :class="[getCleanTypeOfSocialLink(socialLink.type).toLowerCase(), !logoInThemes.includes(portfolioTemplate) ? 'footer-url' : 'footer-icon']">
                                            <div v-if="!logoInThemes.includes(portfolioTemplate)">
                                                {{ getCleanTypeOfSocialLink(socialLink.type) }}
                                            </div>
                                            <i v-else :class="'fa fa-' + socialLink.type.toLowerCase()"></i>
                                        </a>
                                    </template>
                                </AnimationWrapper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="user.accountSettings.folioLogo && project.type !== 'caseStudy'" class="footer-share" :class="portfolio.templateSettings.theme !== 'profile_theme_nine' ? 'show-color-background' : ''" >
        <div class="grid">
            <div class="footer-share-content" :class="portfolio.templateSettings.theme !== 'profile_theme_nine' ? 'show-color-background' : ''">
                <a href="https://uxfol.io/?utm_campaign=branding_header" rel="nofollow" class="ufo--branding floating">
                    Made with <UXFolioLogo />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import SectionText from "../sections/_helper/GetSectionText.vue";
import CtaButton from "../sections/_helper/CtaButton.vue";
import UXFolioLogo from "../../components/UXFolioLogo.vue";
import ToggleGrid from "../../../common/components/ToggleGrid.vue";
import ProjectThumbnail from "../../../sections/projectGrid/ProjectThumbnail.vue";
import AnimationWrapper from "../../../common/components/AnimationWrapper.vue";

export default {
    name: "PortfolioFooter",
    components: {AnimationWrapper, UXFolioLogo, CtaButton, SectionText, ToggleGrid, ProjectThumbnail},
    computed: {
        portfolio() {
          return this.$store.state.view.portfolio
        },
        project() {
            return this.$store.state.view.project
        },
        user() {
            return this.$store.state.view.user
        },
        footerProjects() {
            return this.$store.state.view.footerProjects
        },
        portfolioTemplate() {
            return this.portfolio.templateSettings.theme.replace("_old", "");
        },
        homeLink() {
            return this.portfolio.ownDomainAddress ? ("https://" + this.portfolio.ownDomainAddress) : ("/" + this.portfolio.shortId)
        },
        //TODO: WHAT IS NOT ALLOWED ?
        notAllowedInThemes() {
            return ['theme_one', 'theme_two', 'theme_three', 'theme_six', 'theme_seven', 'theme_eleven']
        },
        logoInThemes() {
            return ['profile_theme_default', 'profile_theme_default_old', 'theme_one', 'profile_theme_one', 'theme_two', 'profile_theme_two', "theme_three", "profile_theme_three", 'theme_six', 'profil_theme_six', 'theme_seven', 'profile_theme_seven', 'theme_eight', 'profile_theme_eight', 'theme_thirteen', 'profile_theme_thirteen', 'theme_seventeen']
        },
        portfolioPages() {
            return this.$store.state.view.portfolioPages
        },
    },
    methods: {
        navigateToProjectFooter(ownDomainAddress, portfolioShortId, projectShortId) {
            const caseStudyRoute = this.portfolio.ownDomainAddress && this.portfolio.ownDomainAddress === location.host
                ? `/p/${projectShortId}`
                : `/p/${portfolioShortId}/${projectShortId}`

            window.location.href = caseStudyRoute
        },
        rootIsOwnDomain() {
            return this.portfolio.ownDomainAddress && this.portfolio.ownDomainAddress === location.host
        },
        homeNavigation(evt) {
            evt.preventDefault();
            const homeRoute = this.rootIsOwnDomain()
                ? "/"
                : `/${this.portfolio.shortId}`
            this.$router.push(homeRoute)
            this.$store.state.view.navOpen = false;
        },
        navigationRef(evt, page) {
            evt.preventDefault();
            const pageRoute = this.rootIsOwnDomain()
                ? `/p/${page.shortId}`
                : `/p/${this.portfolio.shortId}/${page.shortId}`
            this.$router.push(pageRoute)
            this.$store.state.view.navOpen = false;
        },
        getCleanTypeOfSocialLink(linkType) {
            if(linkType.toLowerCase() === "x-twitter") return 'X';

            return this.transformText(linkType)
        },
        transformText(text) {
            return this.portfolio.templateSettings.navigation.navigationItems.button.upperCase
                ? text.toUpperCase()
                : text
        }
    }
}
</script>