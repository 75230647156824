<template>
    <slot></slot>
</template>

<script>
export default {
    name: "ClickOutsideObserver",
    emits: ['click-outside'],
    props: {
        isActive: {
            type: Boolean,
            default: false // Whether the observer should be active
        }
    },
    mounted() {
        if (this.isActive) {
            document.addEventListener("click", this.handleClickOutside);
        }
    },
    beforeUnmount() {
        this.cleanupObserver(); // Clean up event listeners
    },
    watch: {
        isActive(newVal) {
            if (newVal) {
                document.addEventListener("click", this.handleClickOutside);
            } else {
                this.cleanupObserver();
            }
        }
    },
    methods: {
        handleClickOutside(event) {
            // Check if the click occurred outside the component
            if (!this.$el.nextElementSibling.contains(event.target) && !this.$store.state.activeModal) {
                this.$emit("click-outside", event); // Emit event when clicking outside
            }
        },
        cleanupObserver() {
            document.removeEventListener("click", this.handleClickOutside);
        }
    }
};
</script>