<template>
    <modal-frame v-if="show" :show="show" @close="close" :modal-size="'mid'" disable-outside-click>
        <div class="review-welcome-modal-container">
            <div class="weclome-wrapper">
                <div class="image-wrapper">
                    <img alt="review-welcome-picto" src="../../../../images/review/review-welcome.png" />
                </div>
            </div>
            <div class="footer-wrapper">
                <div class="text">Review {{userName}}'s portfolio</div>
                <div class="button-container">
                    <CtaButton type="primary" dark-scheme @click="handlePrimaryClick">{{isFolioUserLoggedIn ? 'Review as ' + uxFolioUserName  : 'Start reviewing' }}</CtaButton>
                    <CtaButton v-if="!isFolioUserLoggedIn" type="link" @click="returnToSession" dark-scheme>Return to previous session</CtaButton>
                </div>
            </div>
        </div>
    </modal-frame>
</template>

<script>
import CtaButton from "../../../common/components/CtaButton.vue";
import ModalFrame from "../../../common/_ModalFrame.vue";
import {ReviewLoginModalStateEnum} from "@/scripts/common/enums.js";
import followUpLogin from "@/scripts/view/review/modals/loginModals/FollowUpLogin.vue";

export default {
    name: "ReviewWelcomeModal",
    props: ['show', 'close', 'getReview' ],
    components: {
        ModalFrame,
        CtaButton,
    },
    data() {
        return {

        }
    },
    methods: {
        returnToSession() {
            if(this.isFolioUserLoggedIn) {
                this.$store.state.user = null;
                this.$reviewStore.reviewLoginModalState = ReviewLoginModalStateEnum.GUEST;
            } else {
                this.$reviewStore.reviewLoginModalState = ReviewLoginModalStateEnum.GUEST_CONTINUE;
            }
            this.close();
            this.$reviewStore.showReviewLoginModal = true;
        },
        handlePrimaryClick() {
            if(this.isFolioUserLoggedIn) {
                if(!this.$reviewStore.review.reviewerDetails) {
                    this.$reviewStore.review.reviewerDetails = this.$store.state.user;
                }
                this.getReview();
            }
            this.close()
        }
    },
    computed: {
        userName() {
            return this.$store.state.view.user ? this.$store.state.view.user.name : 'John Doe'
        },
        uxFolioUserName() {
            return this.$store.state.user ? this.$store.state.user.name : 'John Doe'
        },
        isFolioUserLoggedIn() {
            return this.$store.state.user !== null
        }
    }
}
</script>

<style scoped lang="scss">
    .review-welcome-modal-container {
        display: flex;
        width: 640px;
        flex-direction: column;
        align-items: flex-start;
        flex-shrink: 0;
        border-radius: 8px;
        overflow: hidden;

        .image-wrapper {
            display: flex;
            width: 640px;
            height: 310px;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .footer-wrapper {
            display: flex;
            padding: 32px;
            flex-direction: column;
            align-items: center;
            gap: 32px;
            align-self: stretch;
            box-shadow: 0 5.845px 14.612px 2.922px rgba(12, 44, 76, 0.08);

            .text {
                font: var(--editor-ui-h0);
                color: var(--asphalt10);
            }
            .button-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 16px;
                align-self: stretch;

                .ufo-cta--primary {
                    width: 224px;
                }
            }
        }
    }
</style>