<template>
    <img alt="new-message-picto" src="../../../../../images/review/new-message.png" />
    <div class="header-container">
        <div class="header">You have a follow-up message</div>
        <div class="subheader">Provide your email to see and reply to {{ userName }}'s follow-up message.</div>
    </div>
    <input-field
        dark-scheme
        :label="'Email address'"
        :placeholder="'Email address'"
        :value="email"
        v-model="email"
        :type="'email'"
    >
        <template #alert>
            <span :key="'transferError'" v-if="errors.email" class="alert">Please fill out this field</span>
        </template>
    </input-field>
    <div class="button-container">
        <CtaButton type="primary" dark-scheme @click="continueWithReview">Open my review</CtaButton>
        <span>Do you have a UXfolio account? <CtaButton @click="loginWithUxfolioUser" type="link" dark-scheme>Log in</CtaButton></span>
    </div>
</template>

<script>
    import {event} from "vue-gtag";
    import ApiHelper from "@/scripts/api/ApiHelper.js";
    import {ReviewLoginModalStateEnum, ReviewTypeEnum} from "@/scripts/common/enums.js";
    import CtaButton from "@/scripts/common/components/CtaButton.vue";
    import InputField from "@/scripts/common/components/Input.vue";
    import {reviewStore} from "@/scripts/store/modules/reviewStore.js";

    export default {
        name: "FollowUpLogin",
        props:['getReview', 'setLoginModalState'],
        components: {
            CtaButton,
            InputField
        },
        data() {
            return {
                email:null,
                errors: {
                    email:false
                },
                ReviewLoginModalStateEnum:ReviewLoginModalStateEnum
            }
        },
        methods: {
            async continueWithReview() {
                this.errors.email = !this.email;
                if (this.errors.email) return;

                try {
                    let contentId = this.$reviewStore.reviewType === ReviewTypeEnum.PORTFOLIO ? this.$store.state.view.portfolio._id : this.$store.state.view.project._id;
                    let encodedEmail = encodeURIComponent( this.email);
                    let reviewerResponse = await ApiHelper.get(`/api/review/reviewer?email=${encodedEmail}&contentId=${contentId}`);

                    let review = {...reviewerResponse.data.review, 'reviewerDetails': reviewerResponse.data.reviewer }
                    this.$store.commit('review/updateReview', review);
                    this.$reviewStore.reviews = [review];
                    this.loaded = true;

                    //Add to cookie as well;
                    this.$cookies.set('reviewer', JSON.stringify(response.data.reviewer));

                    this.closeModal();
                }
                catch(e) {
                    if(e.response && e.response.status === 409) {
                        this.setLoginModalState(ReviewLoginModalStateEnum.AUTH_ERROR)
                    } else {
                        this.setLoginModalState(ReviewLoginModalStateEnum.LOGIN_ERROR)
                    }
                }
            },
            loginWithUxfolioUser() {
                //GA Event
                event('Review log-in with UXFolo account', {
                    'event_category': 'Review mode',
                });

                const host = window.location.host;
                const path = "/review/login?host=" + host + "&path=" + window.location.pathname;
                const encodedUrl = `/loginredirect?host=${encodeURIComponent(host)}&redirectTo=${encodeURIComponent(path)}`;
                window.location = encodedUrl;
            },
        },
        computed: {
            userName() {
                return this.$store.state.view.user ? this.$store.state.view.user.name : 'John Doe';
            },
        }
    }
</script>

<style scoped>
    .button-container {
        span {
            font:var(--editor-ui-b2-regular);
            color: var(--asphalt40);
        }
    }
</style>