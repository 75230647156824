<template>
    <div class="header-wrapper">
        <img v-if="user && user.image && user.image.url" :src="user.image.url"/>
        <UserImagePlaceholder v-else :name="userName" size="medium"/>
        <div class="header-container">
            <div class="header">Let {{ userName }} know that this review is from you.</div>
        </div>
    </div>
    <div class="inputs-container">
        <input-field
            dark-scheme
            :label="'Name'"
            :placeholder="'Name'"
            :value="loginDetails.name"
            v-model="loginDetails.name"
        >
            <template #alert>
                <span :key="'transferError'" v-if="errors.name" class="alert">Please fill out this field</span>
            </template>
        </input-field>
        <input-field
            dark-scheme
            :label="'Job title'"
            :placeholder="'Job title'"
            :value="loginDetails.jobTitle"
            v-model="loginDetails.jobTitle"
        >
            <template #alert>
                <span :key="'transferError'" v-if="errors.jobTitle" class="alert">Please fill out this field</span>
            </template>
        </input-field>
        <input-field
            dark-scheme
            :label="'Email address'"
            :placeholder="'Email address'"
            :value="loginDetails.email"
            v-model="loginDetails.email"
            :type="'email'"
        >
            <template #alert>
                <span :key="'transferError'" v-if="errors.email" class="alert">Please fill out this field</span>
            </template>
        </input-field>
    </div>
    <div class="button-container">
        <CtaButton type="primary" dark-scheme @click="checkUser">Review as a guest</CtaButton>
        <span>Do you have a UXfolio account? <CtaButton @click="loginWithUxfolioUser" type="link" dark-scheme>Log in</CtaButton></span>
    </div>
</template>

<script>
import UserImagePlaceholder from "@/scripts/common/components/UserImagePlaceholder.vue";
import CtaButton from "@/scripts/common/components/CtaButton.vue";
import InputField from "@/scripts/common/components/Input.vue";
import ApiHelper from "@/scripts/api/ApiHelper.js";
import {event} from "vue-gtag";
import {ReviewLoginModalStateEnum, ReviewTypeEnum} from "@/scripts/common/enums.js";
import {reviewStore} from "@/scripts/store/modules/reviewStore.js";

export default {
    name: "GuestLogin",
    props:['setLoginModalState', 'getReview'],
    components: {
        UserImagePlaceholder,
        CtaButton,
        InputField
    },
    data() {
        return {
            errors:{
                name:false,
                jobTitle:false,
                email:false,
            }
        }
    },
    methods: {
        async checkUser() {
            let errorsActive = this.checkErrors();
            if(errorsActive) {
                return;
            }

            try {
                let response = await ApiHelper.post("/api/review/reviewer", {name:this.loginDetails.name, jobTitle:this.loginDetails.jobTitle, email:this.loginDetails.email});
                this.$reviewStore.review.reviewerDetails = response.data.reviewer;
                this.$cookies.set('reviewer', JSON.stringify(response.data.reviewer));
                this.getReview();
                this.$reviewStore.showReviewLoginModal = false;
            }
            catch(err) {
                console.log('Error while fetching reviewer', err)

                //check if there were a user
                if(err.response && err.response.status === 409) {
                    let encodedEmail = encodeURIComponent(this.loginDetails.email);
                    let reviewerResponse = await ApiHelper.get(`/api/review/reviewer?email=${encodedEmail}`);
                    this.$cookies.set('reviewer', JSON.stringify(reviewerResponse.data.reviewer));
                    this.setLoginModalState(ReviewLoginModalStateEnum.EXISTING_USER);
                }
                else if(err.response && err.response.status === 403) {
                    this.setLoginModalState(ReviewLoginModalStateEnum.AUTH_ERROR);
                }
                else {
                    console.log('Error while loading Reviewer.')
                }
            }

            //GA Event
            event('Review with Guest account', {
                'event_category': 'Review mode',
                'event_label':  `Reviewer: ${this.loginDetails.email}, Portfolio: ${this.$store.state.view.portfolio._id}`
            });
        },
        checkErrors() {
            let error = false;

            this.errors.name = !this.loginDetails.name;
            this.errors.jobTitle = !this.loginDetails.jobTitle;

            if (this.errors.name || this.errors.jobTitle) {
                error = true;
            }

            this.errors.email = !this.loginDetails.email;
            if (this.errors.email) {
                error = true;
            }

            return error;
        },
        loginWithUxfolioUser() {
            //GA Event
            event('Review log-in with UXFolo account', {
                'event_category': 'Review mode',
            });

            const host = window.location.host;
            const path = "/review/login?host=" + host + "&path=" + window.location.pathname;
            const encodedUrl = `/loginredirect?host=${encodeURIComponent(host)}&redirectTo=${encodeURIComponent(path)}`;
            window.location = encodedUrl;
        },

    },
    computed: {
        user() {
            return this.$store.state.view.user
        },
        userName() {
            return this.$store.state.view.user ? this.$store.state.view.user.name : 'John Doe';
        },
        userJobTitle() {
            return this.$store.state.view.user ? this.$store.state.view.user.title : 'UX designer';
        },
        loginDetails() {
            return this.$reviewStore.loginDetails
        }
    }
}
</script>

<style scoped lang="scss">
    .button-container {
        span {
            font:var(--editor-ui-b2-regular);
            color: var(--asphalt40);
        }
    }
</style>