<template>
    <div id="overview" ref="overview" v-if="$reviewStore.displayUI" :class="['overview-container', {isOpen:isOpen}, {receiver:isReceiverMode}]">
        <div class="header-row" :class="{isOpen:isOpen}" @click="isOpen = !isOpen">
            {{ !isReceiverMode ? 'Overview' : 'Read reviews'}}
            <Icon :icon-name="isOpen ? 'chevron-down' : 'chevron-up'" />
        </div>
        <div v-if="isOpen" class="body-row">
            <template v-if="!isReceiverMode">
                <div class="overviews">
                    <OverviewField :is-input="true" :fieldToUpdate="'positiveAspects'" :title="'Positive aspects'" icon="positive-aspect" :placeholder="positivePlaceholder"/>
                    <OverviewField :is-input="true" :fieldToUpdate="'negativeAspects'" :title="'Areas of improvement'" icon="areas-of-improvement" :placeholder="negativePlaceholder"/>
                </div>
            </template>
            <ReceiverOverview v-else :is-empty="this.isEmpty" />
        </div>
    </div>
</template>

<script>
    import Icon from "../../../common/Icon.vue";
    import OverviewField from "./OverviewField.vue";
    import ReceiverOverview from "./receiver/ReceiverOverview.vue";

    export default {
        name: "OverviewComponent",
        props:['reviewMode', 'isEmpty'],
        components: {
            ReceiverOverview,
            OverviewField,
            Icon
        },
        mounted() {
            if(this.$route.query.followup || this.$route.query.reviewer) {
                this.isOpen = true;
            }
        },
        computed: {
            positivePlaceholder() {
                return 'Write about what you like about this portfolio focusing on the following areas:\n' +
                    '- look & feel' +
                    '\n- information architecture' +
                    '\n- selection of projects' +
                    '\n- quality of visuals'

            },
            negativePlaceholder() {
                return 'Write about what could be improved about this portfolio focusing on the following areas:\n' +
                    '- look & feel' +
                    '\n- information architecture' +
                    '\n- selection of projects' +
                    '\n- quality of visuals'
            },
            isReceiverMode() {
                return this.$reviewStore.receiver || (this.$reviewStore.review && this.$reviewStore.review.submitted)
            },
            isOpen: {
                get() {
                    return this.$reviewStore.showGeneralOverview;
                },
                set(newValue) {
                    this.$reviewStore.showGeneralOverview = newValue;
                }
            }

        }
    }
</script>

<style scoped lang="scss">
    .overview-container {
        position: fixed;
        bottom: 24px;
        left: 24px;
        z-index: 3;
        width: 400px;
        display: flex;
        flex-direction: column;

        border-radius: 12px;
        border: 1px solid var(--asphalt90);
        background: var(--asphalt95);
        box-shadow: 0px 287px 80px 0px rgba(0, 0, 0, 0.00), 0px 183px 73px 0px rgba(0, 0, 0, 0.01), 0px 103px 62px 0px rgba(0, 0, 0, 0.05), 0px 46px 46px 0px rgba(0, 0, 0, 0.08), 0px 11px 25px 0px rgba(0, 0, 0, 0.09);

        &.isOpen {
            height: calc(100% - 105px);
        }

        &.receiver {
            .header-row {
                border-bottom: none !important;
            }
        }

        .header-row {
            display: flex;
            flex-direction: row;
            padding: 20px 20px 20px 24px;
            justify-content: space-between;

            color: var(--asphalt10);
            font: var(--editor-ui-b1-semibold);

            &:hover {
                cursor: pointer;
            }

            svg {
                width: 24px;
                height: 24px;
            }

            &.isOpen {
                border-bottom: 1px solid var(--asphalt80);
            }
        }

        .body-row {
            height: 100%;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            flex: 1 0 0;
            align-self: stretch;

            p {
                margin: 0;
            }

            .overviews {
                display: flex;
                padding: 24px;
                flex-direction: column;
                align-items: flex-start;
                gap: 40px;
                flex: 1 0 0;
                align-self: stretch;
            }
        }
    }
</style>