import {createStore} from 'vuex'
import Router from '../router/Router.js';
import mediumZoom from 'medium-zoom';
import autosize from 'autosize';
import {PortfolioActions} from "./actions/PortfolioActions.js";
import {UpdateSection} from "./actions/SectionActions.js";
import UsersAPI from "../api/users/UserAPI.js";
import {event} from 'vue-gtag';
import {
    DesignPanelPathsEnum,
    EditedImageTypeEnum,
    PageTypesEnum,
} from "../common/enums.js";
import {aboutPageSections, basicSections, homePageRecommendedSections, uxMethods} from "../sections/_SectionTypes.js";
import {getAllTextStyles, getPortfolioHeroTextStyles} from "../common/TextStyleRenderHelper.js";
import { review } from './modules/reviewStore.js';


/* Create a reactive store.state */
export const store = createStore({
    modules: {
        review,
    },
    state() {
        return {
            triggerAnimation: true,
            recalculateScrollTrigger: true,
            emergencyModeSettings: null,
            user: null,
            userPortfolios: null,
            portfolio: null,
            portfolioSetupComplete: false,
            portfolioPages: null,
            caseStudyPages: null,
            allCaseStudyPages: null,
            allPortfolios: null,
            userSettings: {
                currentSubscription : null,
                currentSubscriptionPlan : null,
                domainSubscriptions: [],
                invoices: [],
                stripePrices: {
                    monthlyPrice: {
                        id:'',
                        price:'NaN'
                    },
                    yearlyPrice: {
                        id:'',
                        price:'NaN'
                    }
                },
                referrerData:null,
                referredData:null,
                creditBalance:0,
                showSupportSurvey: false,
            },
            project: null,
            allowedSections: null,
            sectionEditorSettings: {
                pageToEdit: null,
                emptyStateTreshold: 1,
                hideEditButtons: false,
                hideMockupButtons: false,
                hideTextAndImageIdeasButtons: false,
                hiddenMediaTypes: {
                    image: false,
                    video: false,
                    proto: false,
                    media: false,
                },
                maxColumnsPerSection: 4,
                maxStatItemsPerSection: 5,
                defaultPadding: 60,
                sectionLimit: null
            },
            selectedProject: null,
            selectedSection: null,
            selectedPortfolio: null,
            savingProject: false,
            activeModal: null,
            isViewLight: () => {
                let path = Router.currentRoute._value.fullPath;
                return (path.includes('/create-new-portfolio')
                    || path.includes('/home/settings')
                    || path === '/home/portfolios'
                    || path === '/home/notifications'
                    || path.includes('/home/domain')
                    || path.includes('/home/url')
                    || path.includes('/home/payment')
                    || path.startsWith('/reviews'))
            },
            activeModalData: null,
            uploadImageAndCropStep: "upload",
            uploadImageAndCropType: null,
            alertModalMessage: null,
            showSidePanel: false,
            sidePanelSettings: {
                panel: null,
                title: 'Case study design',
                previousSettings: null,
                prevAccentColor: null,
                saveButton: 'Save',
                showSecondaryTab: false,
                hideBackButton: false,
                hideActionButtons: false,
                allowSave: false,
                portfolioPassword: null,
                newPageDetails: null,
                globalSpacingChanged: false,
                editedTextStyle: null,
                activeModal: null,
                goBackPath: null,
                updateParentPortfolio: false,
                tabPills: null,
                activeTab: null,
                storedScrollPosition: null,
            },
            currentSubscriptionPlan: null,
            subscriptionSettingsInfo: null,
            zoom: mediumZoom({
                margin: 24,
                background: 'rgba(255,255,255,0.9)',
                scrollOffset: 0,
            }),
            selectedMockupStyle: null,
            showTipMessage: false,
            selectedStatSectionStyles: null,
            selectedTestimonialSectionStyles: null,
            selectedHeaderSectionStyle: null,
            alertMessage: {
                show: false,
                type: 'info',
                message: null,
                interval: null,
            },

            notifications: [],
            showArchiveInfoBubble: false,
            showOnBoardingScreen: false,
            onboardingFinished: false,
            showScrollIndicator: false,
            templateTipMessageSeen: false,
            pageIsLoading: false,
            loaderMessage: null,
            creatingNewPortfolio: false,
            previewingTemplate:false,
            previewAndCreatePortfolioDetails: {
                selectedPortfolioTheme:null,
                activeView:0,
                selectedProjects:[],
                newPortfolio: null
            },
            fonts:[],
            reviewCenter: {
                reviewSent: false,
                askForReviewProjectId: null
            },
            darkMode:null,
            //TODO: temp
            view: {
                portfolio:null,
                project:null,
                user:null,
                portfolioPages:null,
                activePage:null,
                footerProjects:[],
                navOpen:false,
                fontsLoaded:false,
                ownDomain:null,
                publicPages: {
                    activeMode: "light"
                },
                showcaseItems: null,
                selectedShowcaseItem: null,
                emergencyModeSettings: null,
                stickedHeader:false //Basically just a field to know if there is a sicked header on case studies. for review mode
            },
            displayToggleGrid: false,
            checklist: {
                activeInteraction:null,
                subpage:false,
                finished:false,
                popUpClosed:true
            }
        }
    },
    mutations: {
        setPageToEdit(state, shortId) {
            state.project = state.portfolioPages.find(p => p.shortId === shortId)
            store.commit("setAllowedSections", state.project.type);
            store.commit("setSectionEditorSettings", {
                pageToEdit: !!state.project ? state.project.type : 'blank',
                sectionLimit: 3
            });
        },
        setCaseStudyToEdit(state, shortId) {
            state.project = state.caseStudyPages.find(p => p.shortId === shortId)
            store.commit("setAllowedSections", "caseStudy");
        },
        updateEditedPageSectionsInPortfolioPages(state) {
            state.portfolioPages.find(p => p.shortId === state.project.shortId).sections = state.project.sections;
        },
        updateEditedCaseStudySectionsInCaseStudyPages(state) {
            state.caseStudyPages.find(p => p.shortId === state.project.shortId).sections = state.project.sections;
        },
        addNewCaseStudyToList(state, caseStudy) {
            state.caseStudyPages.push(caseStudy);
        },
        setActiveModal(state, modal) {
            state.activeModal = modal;
        },
        setAllowedSections(state, type) {
            state.allowedSections = Object.assign({}, {
                basicSections: type !== PageTypesEnum.CASE_STUDY ? aboutPageSections : basicSections,
                recommendedSections: type !== PageTypesEnum.CASE_STUDY ? homePageRecommendedSections : uxMethods
            });
        },
        setSectionEditorSettings(state, obj) {
            state.sectionEditorSettings = Object.assign({}, store.state.sectionEditorSettings, obj);
        },
        selectSection(state, section) {
            state.selectedSection = section ? section : null;
        },
        selectColumnIndex(state, index) {
            state.selectedColumnIndex = index;
        },
        selectCaseStudy(state, caseStudy) {
            state.selectedProject = caseStudy
        },
        setCurrentPlan(state, plan) {
            state.currentSubscriptionPlan = plan;
        },
        updateSectionByIndex(state, obj) {
            state.project.sections[obj.sectionIndex] = {
                ...state.project.sections[obj.sectionIndex],
                ...obj.updatedSectionEntries
            }
        },
        updateNewSectionStyle(state, data) {
            switch (data.type) {
                case 'Header':
                    if(state.selectedHeaderSectionStyle) {
                        state.project.sections[data.position].title.style.textStyle = store.state.selectedHeaderSectionStyle ? store.state.selectedHeaderSectionStyle : 'h1';
                        UpdateSection(store.state.project.sections[data.position]);
                    }
                    break;
                case 'Stat':
                    if(store.state.selectedStatSectionStyles) {
                        store.state.project.sections[data.position].statItems.forEach(item => {
                            item.data.style.textStyle = store.state.selectedStatSectionStyles.data ? store.state.selectedStatSectionStyles.data : item.data.style.textStyle;
                            item.description.style.textStyle = store.state.selectedStatSectionStyles.description ? store.state.selectedStatSectionStyles.description : item.description.style.textStyle;
                        });
                        UpdateSection(store.state.project.sections[data.position]);
                    }
                    break;
                case 'Testimonial':
                    if(store.state.selectedTestimonialSectionStyles) {
                        store.state.project.sections[data.position].testimonial.forEach(item => {
                            item.text.style.textStyle = store.state.selectedTestimonialSectionStyles.text ? store.state.selectedTestimonialSectionStyles.text : item.text.style.textStyle;
                            item.author.style.textStyle = store.state.selectedTestimonialSectionStyles.author ? store.state.selectedTestimonialSectionStyles.author : item.author.style.textStyle;
                        });
                        UpdateSection(store.state.project.sections[data.position]);
                    }
                    break;
            }
        },
        updateProject(state, project) {
            store.state.project = Object.assign({}, store.state.project, project);
        },
        updateSavingState(state) {
            state.savingProject = !state.savingProject;
        },
        updateSidePanel(state, panelStates) {
            state.sidePanelSettings = Object.assign({}, store.state.sidePanelSettings, panelStates);
        },
        updateSidePanelVisibility(state, val) {
            state.showSidePanel = val;
        },
        updateSelectedSectionImage(state, newImageObj) {
            store.state.selectedSection.image = Object.assign({}, store.state.selectedSection.image, newImageObj);
        },
        updateSelectedSectionMedia(state, newMediaObj) {
            if(typeof store.state.selectedColumnIndex === 'number') {
                switch (store.state.selectedSection.type) {
                    case 'Columns':
                        state.selectedSection['columns'][state.selectedColumnIndex]['media'] = newMediaObj
                        break;
                    case 'Testimonial':
                        state.selectedSection['testimonial'][state.selectedColumnIndex]['media'] = newMediaObj
                        break;
                    case 'LogoWall':
                        state.selectedSection['logos'][state.selectedColumnIndex] = {
                            ...state.selectedSection['logos'][state.selectedColumnIndex],
                            ...newMediaObj
                        }
                        break;
                }
            } else {
                store.state.selectedSection.media = Object.assign({}, store.state.selectedSection.media, newMediaObj);
            }
        },
        updateSectionMediaBasedOnActiveModalData(state, newObj) {
            if(!state.activeModalData.column) {
                // if the image is a portfolio hero background, update portfolio hero
                // else if the image is a portfolio foreground image update portfolio.image
                // else update the selectedSection
                if(state.activeModalData.key === EditedImageTypeEnum.PORTFOLIO_HERO_IMAGE) {
                    state.portfolio.templateSettings.hero.image = newObj
                    state.selectedSection.backgroundColor = null;
                } else if (state.activeModalData.key === EditedImageTypeEnum.PORTFOLIO_HERO_MEDIA) {
                    state.portfolio.image = newObj
                } else if (state.activeModalData.key === EditedImageTypeEnum.THUMBNAIL) {
                    state.selectedProject.image = newObj
                } else  {
                    // if this is a single column section and has image or media object
                    state.selectedSection[state.activeModalData.key] = newObj
                    if(state.activeModalData.key === EditedImageTypeEnum.IMAGE) {
                        state.selectedSection.backgroundColor = null;
                    }
                }
            } else {
                // if this is a multi-column section like Columns, Testimonial, Gallery
                if(state.activeModalData.column.key !== 'logos') {
                    state.selectedSection[state.activeModalData.column.key][state.activeModalData.column.index].media = newObj;
                } else {
                    // if this is a LogoWall section
                    state.selectedSection['logos'][state.activeModalData.column.index] = newObj
                }
            }
        },
        updateGalleryItems(state, newGalleryItems) {
            state.selectedSection.galleryItems = Object.assign([], state.selectedSection.galleryItems, newGalleryItems)
        },
        updateUser(state, user) {
            store.state.user = Object.assign({}, store.state.user, user);
        },
        updateUserInDb(state, field) {
            if(field) {
                event(field, {
                    'event_category': 'Profile editor',
                    'event_label':  `User: ${store.state.user._id}`
                });
            }
            state.savingProject = true;
            UsersAPI.editUser(state.user)
                .then(response => {
                    state.user = {
                        ...response.data,
                        paymentMethods:state.user.paymentMethods
                    }
                })
                .catch(error => {
                    if(error.response.status === 405) {
                        state.subscriptionSettingsInfo = error.response.data;
                        state.currentSubscriptionPlan = error.response.data.currentSubscriptionPlan;
                        state.activeModal = "SubscriptionSettingsModal";
                        if(error.response.data.code === 1005) {
                            state.user.accountSettings.folioLogo = true;
                        }
                    }
                })
                .finally(() => {
                   state.savingProject = false;
                });
        },
        updateExistingStatSectionStyles(state) {
            state.project.sections.find((item) => {
                if(item.statItems && !item.deletedAt && (item.statItems[0].data.style && item.statItems[0].data.style.textStyle || item.statItems[0].description.style && item.statItems[0].description.style.textStyle) ) {
                    return store.state.selectedStatSectionStyles = {data: item.statItems[0].data.style.textStyle, description: item.statItems[0].description.style.textStyle};
                }
            });
        },
        updateExistingHeaderSectionStyle(state) {
            store.state.project.sections.find((item) => {
                if(item.type === 'Header' && item.title.style.textStyle && !item.deletedAt ) {
                    return store.state.selectedHeaderSectionStyle = item.title.style.textStyle;
                }
            });
        },
        updateExistingTestimonialSectionStyles(state) {
            store.state.project.sections.find((item) => {
                if(item.type === 'Testimonial' && !item.deletedAt) {
                    return store.state.selectedTestimonialSectionStyles = {text: item.testimonial[0].text.style.textStyle, author: item.testimonial[0].author.style.textStyle};
                }
            });
        },
        updateFirstMockup(state) {
            store.state.project.sections.find((item) => {
                return store.state.selectedMockupStyle = item.media && item.media.frame ? item.media.frame : (item.galleryItems && item.galleryItems[0].media.frame ? item.galleryItems[0].media.frame : 'mobile--semi-realistic--light--rounded--shadow');
            });
        },
        openSidePanel(state, data) {
            store.commit("openSidePanelOnPage",data);
        },
        openSidePanelOnPage(state, data) {
            switch(state.project.type) {
                case PageTypesEnum.HOME:
                    Router.push(`/home/portfolios/${store.state.portfolio.shortId}/${data.panelPath}`);
                    break;
                default:
                    Router.push(`/home/portfolios/${store.state.portfolio.shortId}/p/${store.state.project.shortId}/${data.panelPath}`);
                    break;
            }
        },
        closeSidePanel(state) {
            switch(state.project.type) {
                case PageTypesEnum.HOME:
                    Router.push(`/home/portfolios/${state.portfolio.shortId}`);
                    break;
                default:
                    Router.push(`/home/portfolios/${state.portfolio.shortId}/p/${state.project.shortId}`);
                    break;
            }
        },
        toggleSidePanel(state, data) {
            if(location.pathname.indexOf(`/${data.panelPath}`) < 0) {
                store.commit("openSidePanel", data)
            } else {
                store.commit("closeSidePanel");
            }
        },
        navigateBetweenSidePanels(state, data) {
            // if there is no data or data.mainPanel the router goes to Design panel
            // if there is no data.targetPanelPath the router goes to main panel
            // targetPanelPath should start with /
            let path;
            const mainPanel = data && data.mainPanel ? data.mainPanel : DesignPanelPathsEnum.ROOT
            switch(state.project.type) {
                case PageTypesEnum.HOME:
                    path = `/home/portfolios/${state.portfolio.shortId}/${mainPanel}`;
                    break;
                default:
                    path = `/home/portfolios/${state.portfolio.shortId}/p/${state.project.shortId}/${mainPanel}`
            }

            if(!data || data && !data.targetPanelPath) return Router.push(path);

            return Router.push(path + data.targetPanelPath);

        },
        setChatBubblePosition(state, x) {
            const widgetContainer = document.querySelector('#freshworks-container');
            const widgetLauncher = document.querySelector('#launcher-frame');
            if(!!widgetContainer) {
                widgetContainer.style.cssText += `--leftPos: ${x}px;`;
            }
            if(!!widgetLauncher) {
                widgetLauncher.style.cssText += `--leftPos: ${x}px;`;
            }
        },
        async openPortfolioToEdit(state, portfolioId) {
            await PortfolioActions.getPortfolios(portfolioId);
            store.commit("setPageToEdit", "home");
            await Router.push(`/home/portfolios/${state.portfolio.shortId}`)
        },
        setHeightOfTextareas(state) {
            autosize(document.querySelectorAll('textarea'));
            setTimeout(() => { autosize.update(document.querySelectorAll('textarea')) }, 10);
        },
        scrollToSection(state, data) {
            let s = document.querySelector(`#section-${data.sectionIndex}`);
            if(!!s) {
                s.classList.toggle('restore.stated', !!data.restoreSection);
                let scrollVal = offset(s) - (s.offsetHeight <= window.innerHeight ? ((window.innerHeight - s.offsetHeight) / 2 + 29) : 58);
                window.scrollTo({
                    top: scrollVal,
                    left: 0,
                    behavior: 'smooth'
                });
            }
            function offset(el) {
                const rect = el.getBoundingClientRect(),
                    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                return rect.top + scrollTop
            }
        },
        showAlertMessage(state, data) {
            state.alertMessage = {
                type: data.type,
                message: data.message,
                show: data.show === false ? data.show : true,
                interval: data.interval ? data.interval : 3,
            }
        },
        subscriptionChecker(state, message){
            if (store.state.currentSubscriptionPlan === 'Free') {
                store.state.subscriptionSettingsInfo = message;
                store.state.activeModal = "SubscriptionSettingsModal";
            }
        },
        scrollToItem(state, item) {
            let scrollToItem = item.index-1 > -1 ? document.querySelector(`[data-id="${item.index-1}"]`) : null;
            let scrollToSection = document.querySelector(`[data-section-id="${store.state.selectedSection.index}"]`);
            let scrollVal = scrollToSection.offsetTop - (scrollToSection.offsetHeight <= window.innerHeight ? ((window.innerHeight - scrollToSection.offsetHeight) / 2 + 29) : 58) + (scrollToItem ? scrollToItem.offsetTop : 0);
            window.scrollTo({top: scrollVal, left: 0, behavior: 'smooth'});
        },
        selectTextStyleToEdit(state, textStyle) {
            state.sidePanelSettings.editedTextStyle = textStyle;
        },
        updateColorInTextStyle(state, color) {
            // if the edited color is not inside a text style object (accentColor, backgroundColor)
            if(!state.sidePanelSettings.editedTextStyle.type) {
                return state.sidePanelSettings.editedTextStyle = color;
            }

            state.sidePanelSettings.editedTextStyle.color = color;
        },
        updateSelectedTextStyle(state, textStyle) {
            state.sidePanelSettings.editedTextStyle = textStyle
        },
        updatePortfolioInUserPortfolios(state, portfolio) {
            const portfolioIndex = state.userPortfolios.findIndex(p => p.shortId === portfolio.shortId)
            state.userPortfolios[portfolioIndex] = portfolio;
        },
        openLink(state, link) {
            window.open(link);
        },
        updateView(state, view) {
            state.view = {...state.view, ...view}
        },
        woopraTrack(state, eventData) {
            // eventData = {
            //     name: 'MY_CUSTOM_EVENT_NAME',
            //     properties: {property_one: 'value', property_two: 'value'}
            // }
            if(window.woopra) {
                if (eventData) {
                    window.woopra.track(
                        eventData.name,
                        eventData.properties
                    )
                }
                else {
                    window.woopra.track();
                }
            }
        },
        matomoTrack(state, eventData) {
            if(window._paq) {
                if (eventData) {
                    if(eventData.name === "trackPageView") {
                        window._paq.push(['setDocumentTitle', eventData.title]);
                        window._paq.push(['setCustomUrl', eventData.url]);
                        window._paq.push(['trackPageView']);
                        return
                    }

                    // window_paq.push(['trackEvent', eventCategory, eventAction, eventName, eventValue]);
                    window._paq.push(['trackEvent', eventData.category, eventData.action, eventData.name, eventData.category.value]);
                } else {
                    window._paq.push(['trackPageView']);
                }
            }
        },
        recalculateScrollTriggers(state) {
            state.recalculateScrollTrigger = !state.recalculateScrollTrigger;
        },
        updateChecklistItem(state, step) {
            //Only do anything if value not null. For all old users value will be null
            if(state.user.checklistStatus) {
                state.user.checklistStatus.steps[step] = true;
                state.checklist.activeInteraction = null;

                let action = 'Finished: ' + step;
                event(action, {
                    'event_category': 'Activation checklist',
                    'event_label': `User: ${state.user._id}`
                });

                store.commit('updateUserInDb');
            }
        },
        updateActivationPopupState(state, value) {
            state.checklist.popUpClosed = value;
        }
    },
    getters: {
        isDefaultAccentColor() {
            if(!store.state.project.settings || store.state.project.settings.colors && !store.state.project.settings.colors.accent) {
                return true;
            }
        },
        getProjectColor: (state) => (colorOption) => {
            let color = null;
            if (store.state.project && store.state.project.settings && store.state.project.settings.colors) {
                color = store.state.project.settings.colors[colorOption];
            }
            if(!color) {
                color = (colorOption === "dark") ? "#353738"
                    : (colorOption === "light") ? "#ffffff"
                        : (colorOption === "body") ? "#353738"
                            : (colorOption === "accent") ? null
                                : "#000000";
            }
            return color;
        },
        userIsPremium() {
            return store.state.currentSubscriptionPlan !== 'Free';
        },
        caseStudies(state) {
            return state.caseStudyPages
        },
        getAllTextStylesInCss() {
            const fontStyles = store.getters.isCaseStudy && !store.getters.usingGlobalFontStyles ? store.state.project.designSettings.fontStyles : store.state.portfolio.designSettings.fontStyles;
            const colorStyles = store.getters.isCaseStudy && !store.getters.usingGlobalColorStyles ? store.state.project.designSettings.colorStyles : store.state.portfolio.designSettings.colorStyles;
            const ctaStyle = store.getters.isCaseStudy && !store.getters.usingGlobalFontStyles ? store.state.project.settings.ctaStyle : store.state.portfolio.templateSettings.ctaStyle;

            let borderColor = colorStyles.navigation.color;
            if (borderColor < 8) {
                borderColor += store.state.portfolio.templateSettings.theme === 'profile_theme_default_old' ||
                (store.state.portfolio.templateSettings.theme !== 'profile_theme_seven' &&
                    store.state.portfolio.templateSettings.theme !== 'theme_thirteen') ? '33' : '';
            }

            let textStylesCssVars = {};

            // process text related styles based on styleProperties list
            textStylesCssVars = {
                ...getAllTextStyles(fontStyles, colorStyles, ctaStyle)
            };

            // add none text related styles and colors to the cssVars object
            textStylesCssVars = {
                ...textStylesCssVars,
                '--border-color': borderColor,
                '--link-color': colorStyles.hyperLink.color,
                '--link-hover-color': colorStyles.hyperLink.color + "CC",
                '--background-color': colorStyles.background.color,
                '--divider-line-color': colorStyles.dividerLine.color,
                '--sidebar-background-color': colorStyles.sidebarBackground.color
            }

            // In the case of sidebar templates we need to load portfolio hero fonts and colors in the case studies too
            if(
                ['theme_nine', 'profile_theme_nine', 'theme_four', 'profile_theme_four'].includes(store.state.portfolio.templateSettings.theme)
            ) {
                const portfolioFontStyles = store.state.portfolio.designSettings.fontStyles;
                const portfolioColorStyles = store.state.portfolio.designSettings.colorStyles;
                const portfolioBorderColor = portfolioColorStyles.navigation.color + '33';

                // process text related styles based on portfolioStyleProperties list
                textStylesCssVars = {
                    ...textStylesCssVars,
                    ...getPortfolioHeroTextStyles(portfolioFontStyles, portfolioColorStyles)
                };

                // add none text related styles and colors to the cssVars object
                textStylesCssVars = {
                    ...textStylesCssVars,
                    '--portfolio-border-color': portfolioBorderColor,
                    '--portfolio-link-color': portfolioColorStyles.hyperLink.color,
                    '--portfolio-link-hover-color': portfolioColorStyles.hyperLink.color + "CC",
                    '--portfolio-sidebar-background-color': portfolioColorStyles.sidebarBackground.color
                }
            }

            return textStylesCssVars;
        },
        pageViewURL(state) {
            const root = !!state.portfolio.ownDomainAddress && !state.project.draft ? `https://${state.portfolio.ownDomainAddress}` : location.origin;
            const subPagePath = Router.currentRoute.value.params.projectShortId;
            let url = !!state.portfolio.ownDomainAddress ? root : `${root}/${state.portfolio.shortId}`;

            // set path
            if(!!subPagePath) {
                if(!!state.portfolio.ownDomainAddress && !state.project.draft) {
                    url = `${root}/p/${subPagePath}`
                } else {
                    url = `${root}/p/${state.portfolio.shortId}/${subPagePath}`
                }
            }

            return url
        },
        isHomePage(state) {
            return state.project && state.project.type === PageTypesEnum.HOME;
        },
        isCaseStudy(state) {
            return state.project && state.project.type === PageTypesEnum.CASE_STUDY;
        },
        usingGlobalFontStyles(state) {
            return state.project.type === PageTypesEnum.CASE_STUDY && state.project.designSettings.globalFontStyles;
        },
        usingGlobalColorStyles(state) {
            return state.project.type === PageTypesEnum.CASE_STUDY && state.project.designSettings.globalColorStyles;
        },
        animationSettings(state) {
            return state.project && state.project.type === PageTypesEnum.CASE_STUDY && !state.project.designSettings.globalAnimationSettings ?
                state.project.designSettings && state.project.designSettings.animationSettings ? state.project.designSettings.animationSettings : null :
                state.portfolio.designSettings && state.portfolio.designSettings.animationSettings ? state.portfolio.designSettings.animationSettings : null;
        }
    }
});
