<template>
    <button
        :class="['tertiary-btn', {'disabled': disabled, 'dark': darkScheme}, type]"
    >
        <icon v-if="icon" :icon-name="icon"/>
        <div v-if="text">{{ text }}</div>
    </button>
</template>

<script>

import Icon from "../Icon.vue";

export default {
    name: 'tertiary-button',
    components: {Icon},
    props: {
        icon: {
            type: String,
            default: null
        },
        text: {
            type: String,
            default: null
        },
        type: {
            type: String,
            default: 'neutral' // type options: neutral, alert, subtle
        },
        disabled: {
            type: Boolean,
            default: false
        },
        darkScheme: {
            type: Boolean,
            default: false
        },

    },
}
</script>

<style lang="scss" scoped>
button {

    //	neutral
    --tertiary-neutral-text : var(--asphalt10);
    --tertiary-neutral-bg: var(--asphalt80);
    --tertiary-neutral-bg-hover : var(--asphalt100);
    --tertiary-neutral-text-disabled : var(--asphalt40);
    --tertiary-neutral-bg-disabled : var(--asphalt20);

    //	alert
    --tertiary-alert-text : var(--asphalt10);
    --tertiary-alert-bg: var(--error50);
    --tertiary-alert-bg-hover : var(--error40);

    // subtle
    --tertiary-subtle-text: var(--asphalt100);
    --tertiary-subtle-bg: var(--asphalt20);
    --tertiary-subtle-bg-hover: var(--asphalt30);

    &.dark {
        //	neutral dark
        --tertiary-neutral-text: var(--asphalt100);
        --tertiary-neutral-bg: var(--asphalt10);
        --tertiary-neutral-bg-hover: var(--asphalt30);
        --tertiary-neutral-text-disabled: var(--asphalt60);
        --tertiary-neutral-bg-disabled: var(--asphalt90);

        // subtle dark
        --tertiary-subtle-text: var(--asphalt10);
        --tertiary-subtle-bg: var(--asphalt90);
        --tertiary-subtle-bg-hover: var(--asphalt80);
    }

    background-color: var(--tertiary-neutral-bg);
    border-radius: 100px;
    border: none;
    display: inline-flex;
    padding: 6px 14px;
    align-items: center;
    gap: 6px;
    font: var(--editor-ui-btn-l-small);
    color: var(--tertiary-neutral-text);
    cursor: pointer;
    height: 30px;

    &:focus {
        outline: none;
    }
    &:hover {
        background-color: var(--tertiary-neutral-bg-hover);
    }
    &.disabled {
        color: var(--tertiary-neutral-text-disabled);
        background-color: var(--tertiary-neutral-bg-disabled);
    }
    &.alert {
        color: var(--tertiary-alert-text);
        background-color: var(--tertiary-alert-bg);
        &:hover {
            background-color: var(--tertiary-alert-bg-hover);
        }
    }
    &.subtle {
        color: var(--tertiary-subtle-text);
        background-color: var(--tertiary-subtle-bg);
        &:hover {
            background-color: var(--tertiary-subtle-bg-hover);
        }
    }
    svg {
        width: 16px;
        height: 16px;
    }
}
</style>