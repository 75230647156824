<template>
    <div class="continue-review">
        <div class="header-row">
            <div class="title-text">Jump back into a review you started earlier.</div>
        </div>
        <input-field
            dark-scheme
            :label="'Your email'"
            :placeholder="'Your email'"
            :value="loginDetails.email"
            v-model="loginDetails.email"
        >
            <template #alert>
                <span :key="'transferError'" v-if="errors.email" class="alert">Please fill out this field</span>
            </template>
        </input-field>
        <div class="button-container">
            <CtaButton type="primary" dark-scheme @click="continueWithReview">Start reviewing</CtaButton>
            <span>Or <CtaButton type="link" @click="startNewReview" dark-scheme>Start a new review</CtaButton></span>
        </div>
    </div>
</template>

<script>
    import CtaButton from "../../../../common/components/CtaButton.vue";
    import InputField from "@/scripts/common/components/Input.vue";
    import ApiHelper from "@/scripts/api/ApiHelper.js";
    import {ReviewLoginModalStateEnum, ReviewTypeEnum} from "@/scripts/common/enums.js";
    import loginError from "@/scripts/view/review/modals/loginModals/LoginError.vue";
    import {reviewStore} from "@/scripts/store/modules/reviewStore.js";

    export default {
        name: "ContinueLogin",
        props: ['setLoginModalState', 'getReview'],
        components: {
            InputField,
            CtaButton
        },
        data() {
            return {
                email:null,
                errors: {
                    email:false
                },
                ReviewLoginModalStateEnum:ReviewLoginModalStateEnum
            }
        },
        methods: {
            async continueWithReview() {
                this.errors.email = !this.loginDetails.email;
                if (this.errors.email) return;

                try {
                    let contentId = this.$reviewStore.reviewType === ReviewTypeEnum.PORTFOLIO ? this.$store.state.view.portfolio._id : this.$store.state.view.project._id;
                    let { email } = this.loginDetails;
                    let encodedEmail = encodeURIComponent(email);
                    let reviewerResponse = await ApiHelper.get(`/api/review/reviewer?email=${encodedEmail}&contentId=${contentId}`);

                    let review = {...reviewerResponse.data.review, 'reviewerDetails': reviewerResponse.data.reviewer };
                    this.$store.commit('review/updateReview', review);
                    this.$reviewStore.reviews = [review];
                    this.$cookies.set('reviewTour', JSON.stringify({reviewTourDone: true}));
                    this.$reviewStore.showTour = false;
                    this.loaded = true;

                    //Add to cookie as well;
                    this.$cookies.set('reviewer', JSON.stringify(reviewerResponse.data.reviewer));
                    this.closeModal();
                }
                catch(e) {
                    if(e.response && e.response.status === 409) {
                        this.setLoginModalState(ReviewLoginModalStateEnum.AUTH_ERROR)
                    } else {
                        this.setLoginModalState(ReviewLoginModalStateEnum.LOGIN_ERROR)
                    }
                }
            },
            startNewReview() {
                this.$reviewStore.showReviewLoginModal = false;
                this.setLoginModalState(ReviewLoginModalStateEnum.GUEST);
            },
            closeModal() {
                this.$reviewStore.showReviewLoginModal = false;
            }
        },
        computed: {
            loginDetails() {
                return this.$reviewStore.loginDetails
            }
        }
    }
</script>

<style scoped lang="scss">
    .continue-review {
        display: flex;
        gap: 32px;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .header-row {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;

            .title-text {
                font: var(--editor-ui-h2);
                color: var(--asphalt10);
                text-align: center;
            }

            .subtitle-text {
                text-align: center;
                font: var(--editor-ui-b1-regular);
                color: var(--asphalt30);
            }
        }

        .button-container {
            span {
                font:var(--editor-ui-b2-regular);
                color: var(--asphalt40);
            }
        }
    }
</style>