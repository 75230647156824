<template>
    <div class="section-container" v-bind:key="index" v-for="(section, index) in sections"  @mouseenter="hover = index" @mouseleave="hover = null">
        <FabWrapper v-if="isReviewModeActive && $reviewStore.review" :invertedIndex="(sections.length - index + 2)" :hovering="hover === index" :section="section"/>
        <component :is="getSection(section)" :section="section" :style="[getSpacing(section)]"></component>
    </div>
    <!-- load footer at the end of the page after the sections -->
    <div class="section-container" v-if="isFooterEnabled" @mouseenter="hover = footerIndex" @mouseleave="hover = null">
        <FabWrapper v-if="isReviewModeActive && $reviewStore.review" :invertedIndex="2" :hovering="hover === footerIndex" :section="{_id:'footer'}" />
        <PortfolioFooter />
    </div>
</template>

<script>
import MainHeader from "./MainHeader.vue";
import Columns from "./Columns.vue";
import Header from "./Header.vue";
import Text from "./Text.vue";
import TextAndMedia from "./TextAndMedia.vue";
import Stat from "./Stats.vue";
import Media from "./Media.vue";
import Gallery from "./Gallery.vue";
import ContactForm from "./ContactForm.vue";
import ProjectGrid from "./ProjectGrid.vue";
import Cta from "./Cta.vue";
import Testimonial from "./Testimonial.vue";
import LogoWall from "./LogoWall.vue";
import Navigation from "./Navigation.vue";
import DividerLine from "./DividerLine.vue";
import PortfolioFooter from "../common/PortfolioFooter.vue";
import FabWrapper from "../../review/components/FabWrapper.vue";
import {reviewStore} from "@/scripts/store/modules/reviewStore.js";

export default {
    name: "SectionLoader",
    components: {
        FabWrapper,
        PortfolioFooter
    },
    props: ['sections', 'defaultImageAlt'],
    data() {
        return {
            hover: null
        }
    },
    methods: {
        getSection(section) {
            let components =  [
                {type: 'MainHeader', component:MainHeader},
                {type: 'Header', component:Header},
                {type: 'Text', component:Text},
                {type: 'TextAndMedia', component:TextAndMedia},
                {type: 'Stat', component:Stat},
                {type: 'Media', component:Media},
                {type: 'Gallery', component:Gallery},
                {type: 'Columns', component:Columns},
                {type: 'ContactForm', component:ContactForm},
                {type: 'ProjectGrid', component:ProjectGrid},
                {type: 'Cta', component:Cta},
                {type: 'Testimonial', component:Testimonial},
                {type: 'LogoWall', component:LogoWall},
                {type: 'Navigation', component:Navigation},
                {type: 'DividerLine', component:DividerLine}
            ]

            return components.find(component => component.type === section.type).component
        },
        getSpacing(section) {
            if(this.project.settings && this.project.settings.globalSpacingEnabled) {
                if(this.project.settings.globalSpacing) {
                    return {
                        '--section-padding-top': this.project.settings.globalSpacing + 'px',
                        '--section-padding-bottom': this.project.settings.globalSpacing + 'px'
                    }
                }
                else {
                    return {
                        '--section-padding-top': '0px',
                        '--section-padding-bottom': '0px'
                    }
                }
            }
            else {
                let style = {};
                if(section.style) {
                    if(section.style.paddingTop !== null) {
                        style['--section-padding-top'] = section.style.paddingTop + 'px'
                    }

                    if(section.style.paddingBottom !== null) {
                        style['--section-padding-bottom'] = section.style.paddingBottom + 'px'

                    }
                }
                return style
            }
        }
    },
    computed: {
        project() {
            return this.$store.state.view.project;
        },
        portfolio() {
            return this.$store.state.view.portfolio;
        },
        isReviewModeActive() {
            return this.$reviewStore.isReviewMode;
        },
        footerDisabledInThemes() {
            return ['theme_four', 'profile_theme_four', 'theme_nine', 'profile_theme_nine', 'theme_default', 'profile_theme_default']
        },
        footerIndex() {
            return this.sections.length + 1
        },
        portfolioTemplate() {
            return this.portfolio.templateSettings.theme.replace("_old", "");
        },
        isFooterEnabled() {
            let avalibleInCaseStudy = this.project.type === 'caseStudy' && this.portfolio.settings.showFooterOnProjects && this.portfolio.portfolioFooter
            let avalibleInPublicPage = this.project.type !== 'caseStudy' && this.portfolio.settings.showFooterOnPortfolio && !this.footerDisabledInThemes.includes(this.portfolioTemplate) && this.portfolio.portfolioFooter;
            return avalibleInCaseStudy || avalibleInPublicPage
        },
    }
}
</script>

<style scoped lang="scss">
    //For sticky review buttons
    .section-container {
        position: relative;
    }
</style>