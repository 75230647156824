<template>
    <div
        @mouseenter="hover = -1" @mouseleave="hover = null"
        class="hero-container"
    >
        <FabWrapper :class="'hero'" v-if="isReviewModeActive && $reviewStore.review && displayHero" :invertedIndex="zIndexForHero" :hovering="hover === -1" :section="{_id:'portfolio-hero'}" />
        <component :is="getHero"></component>
    </div>
</template>

<script>
import ThemeX from "../ThemeX.vue";
import ThemeDefaultOld from "../legacy_themes/ProfileThemeDefaultOld.vue";
import ProfileThemeDefault from "../old_layouts/ProfileThemeDefault.vue";
import ProfileThemeCommon from "../old_layouts/ProfileThemeCommon.vue";
import ProfileThemeThree from "../old_layouts/ProfileThemeThree.vue";
import ProfileThemeThreeOld from "../legacy_themes/ProfileThemeThreeOld.vue";
import ProfileThemeFour from "../old_layouts/ProfileThemeFour.vue";
import ProfileThemeFive from "../old_layouts/ProfileThemeFive.vue";
import ProfileThemeSix from "../old_layouts/ProfileThemeSix.vue";
import ProfileThemeSeven from "../old_layouts/ProfileThemeSeven.vue";
import ProfileThemeEight from "../old_layouts/ProfileThemeEight.vue";
import ProfileThemeNine from "../old_layouts/ProfileThemeNine.vue";

//Review Fabs
import FabWrapper from "../../../../view/review/components/FabWrapper.vue";
import {reviewStore} from "@/scripts/store/modules/reviewStore.js";

export default {
        name: "PortfolioHeroHelper",
        components: {
            FabWrapper,
        },
        data() {
            return {
                hover:null,
            }
        },
        computed: {
            getHero() {
                let theme = this.$store.state.view.portfolio.templateSettings.theme

                let components = {
                    'profile_theme_default_old': ThemeDefaultOld,
                    'profile_theme_default': ProfileThemeDefault,
                    'profile_theme_one': ProfileThemeCommon,
                    'profile_theme_two': ProfileThemeCommon,
                    'profile_theme_three': ProfileThemeThree,
                    'profile_theme_three_old': ProfileThemeThreeOld,
                    'profile_theme_four': ProfileThemeFour,
                    'profile_theme_five': ProfileThemeFive,
                    'profile_theme_six': ProfileThemeSix,
                    'profile_theme_seven': ProfileThemeSeven,
                    'profile_theme_eight': ProfileThemeEight,
                    'profile_theme_nine': ProfileThemeNine,
                }

                return Object.keys(components).includes(theme) ? components[theme] : ThemeX
            },
            portfolio() {
                return this.$store.state.view.portfolio
            },
            zIndexForHero() {
                return this.$store.state.view.project.sections.length + 3;
            },
            displayHero() {
                return !window.location.pathname.includes('/p/')
            },
            isReviewModeActive() {
                return this.$reviewStore.isReviewMode;
            },
        }
    }
</script>

<style lang="scss" scoped>
    .hero-container {
        position: relative;
    }
</style>