<template>
    <ModalWrapper :show="show" @close="close" :modal-size="'medium'" dark-scheme>
        <template #modal-header>
            <ModalHeader
                :title="'Review summary'"
                dark-scheme
            >
                <template v-if="reviewState !== ReviewStateEnum.FINISHED" #titlePill>
                    <div :class="['pill', reviewState]">
                        {{reviewState}}
                    </div>
                </template>
                <template #tabPills>
                    <TabBar dark-scheme hide-border>
                        <TabItem v-bind:key="tab.key"
                             v-for="tab in tabs"
                             :icon="tab.svg"
                             :label="tab.name"
                             :active="tab.key === activeTab"
                             dark-scheme
                             @click="() => changeTab(tab.key)">
                            <template v-if="checkIndicator(tab.key)" #indicator><div class="dot"></div></template>
                        </TabItem>
                    </TabBar>
                </template>
            </ModalHeader>
        </template>
        <template #modal-body>
            <ModalBody>
                <div class="tab-data">
                    <div class="overview" v-if="activeTab === 'overview'">
                        <OverviewField :fieldToUpdate="'positiveAspects'" :is-input="false" :title="'positive aspects'" icon="positive-aspect" />
                        <OverviewField :fieldToUpdate="'negativeAspects'" :is-input="false" :title="'areas of improvement'" icon="areas-of-improvement" />
                    </div>
                    <div class="comments" v-if="activeTab === 'comments'">
                        <template v-if="sectionReactionComments.length">
                            <div class="selector-container">
                                <DropDownSelect
                                    :label="'Show comments on'"
                                    :selected="getSelectedName(selectedPage.shortId)"
                                    :options="availablePages.map(x => x.name)"
                                    @click="updateSelected($event)"
                                    dark-scheme
                                />
                            </div>
                            <div class="comments-list">
                                <Comment
                                    v-bind:key="comment.label"
                                    v-for="(comment, index) in sectionReactionComments"
                                    :comment="comment"
                                    :edit="false"
                                    :index="index"
                                />
                            </div>
                        </template>
                        <div class="comments-empty-container" v-else>
                            <ReviewResultEmptyState :title="'comments'" :button-text="'Add comments'" :handle-click="() => this.close()"/>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </template>
        <template #modal-footer>
            <ModalFooter v-if="!admin">
                <CtaButton dark-scheme type="secondary" @click="close">Back to reviewing</CtaButton>
                <CtaButton dark-scheme type="primary" :disabled="isDisabled" @click="submitReview">Submit review</CtaButton>
            </ModalFooter>
        </template>
    </ModalWrapper>
</template>

<script>
import CtaButton from "../../../common/components/CtaButton.vue";
import OverviewField from "../components/OverviewField.vue";
import Comment from "../components/Comment.vue";
import ApiHelper from "../../../api/ApiHelper.js";
import DropDownSelect from "../../../common/components/DropDownSelect.vue";
import ModalWrapper from "../../../sections/modals/_ModalWrapper.vue";
import ModalFooter from "../../../sections/modals/_ModalFooter.vue";
import ModalHeader from "../../../sections/modals/_ModalHeader.vue";
import TabBar from "../../../common/components/TabBar.vue";
import TabItem from "../../../common/components/TabItem.vue";
import ModalBody from "../../../sections/modals/_ModalBody.vue";
import {event} from "vue-gtag";
import {ReviewStateEnum} from "@/scripts/common/enums.js";
import ReviewResultEmptyState from "@/scripts/view/review/components/ReviewResultEmptyState.vue";

export default {
        name: "OverviewModal",
        props: ['show', 'close', 'admin', 'openSuccessModal'],
        components: {
            ReviewResultEmptyState,
            ModalBody,
            TabItem,
            TabBar,
            ModalHeader,
            ModalFooter,
            ModalWrapper,
            DropDownSelect,
            CtaButton,
            OverviewField,
            Comment
        },
        data() {
            return {
                tabs: [
                    {key:'overview', name:'Overview', svg:null},
                    {key:'comments', name:'Comments', svg:null},
                ],
                activeTab:'overview',
                selectedPage:{
                    name:'',
                    shortId:'home'
                },
                isDisabled:false,
                ReviewStateEnum:ReviewStateEnum
            }
        },
        mounted() {
            let pages = this.availablePages;
            if(pages.length) {
                this.selectedPage = pages[0]
            }
        },
        methods: {
            async submitReview() {
                try {
                    let res = await ApiHelper.post('/api/review/submit/' + this.$reviewStore.review._id + "/" + this.$reviewStore.review.contentId);
                    //GA Event
                    event('Submit Review', {
                        'event_category': 'Review mode',
                        'event_label':  `Reviewer: ${this.$reviewStore.review.reviewerDetails.email}, Portfolio: ${this.$store.state.view.portfolio._id}`
                    });
                    if(this.$store.state.user) {
                        this.close();
                        this.openSuccessModal();
                    }else {
                        window.location.replace(window.location.origin + "/result");
                    }
                }
                catch(err) {
                    console.log('Failed to save review', err)
                }
            },
            changeTab(tab) {
                this.activeTab = tab
            },
            updateSelected(page) {
                this.selectedPage = this.availablePages.find(x => x.name === page);
            },
            getSelectedName(shortId) {
                let page = this.availablePages.find(x => x.shortId === shortId)
                return page.name
            },
            htmlToString(text) {
                return text.replaceAll("<p>", "")
                    .replaceAll("</p>","")
                    .replaceAll("<ul>", "")
                    .replaceAll("</ul>", "")
                    .replaceAll("<li>", "")
                    .replaceAll("</li>", "")
            },
            checkIndicator(tabkey) {
                if(this.reviewState === ReviewStateEnum.EMPTY || this.reviewState === ReviewStateEnum.FINISHED) {
                    return false
                }
                else {
                    if(tabkey === 'overview') {
                        return !this.positiveAspectsDone || !this.negativeAspectsDone
                    }
                    if(tabkey === 'comments') {
                        return !this.sectionInteractionAdded
                    }
                }
            }
        },
        computed: {
            sectionReactionComments() {
                let commentsWithPage =  this.$reviewStore.review.sectionReactions.map(x => x.comments.map(comment => { return {...comment, page: x.pageData.shortId} })).flat();
                return commentsWithPage.filter(comment => comment.page === this.selectedPage.shortId);
            },
            availablePages() {
                let uniquePageData = [];
                const uniqueIds = new Set();

                this.$reviewStore.review.sectionReactions.forEach(section => {
                    if (!uniqueIds.has(section.pageData.shortId) && section.comments.length) {
                        uniqueIds.add(section.pageData.shortId);
                        uniquePageData.push(section.pageData);
                    }
                });

                return uniquePageData
            },
            reviewState() {
                const progressCount = [
                    this.positiveAspectsDone,
                    this.negativeAspectsDone,
                    this.sectionInteractionAdded
                ].filter(Boolean).length; // Using Boolean to filter true values directly

                this.isDisabled = progressCount < 1; // Disable unless all steps are completed

                switch (progressCount) {
                    case 0:
                        return ReviewStateEnum.EMPTY;
                    case 1:
                    case 2:
                        return ReviewStateEnum.INCOMPLETE;
                    default:
                        return ReviewStateEnum.FINISHED;
                }
            },
            positiveAspectsDone(){
                return this.$reviewStore.review.overview.positiveAspects != null && this.htmlToString(this.$reviewStore.review.overview.positiveAspects).length >= 1
            },
            negativeAspectsDone(){
                return this.$reviewStore.review.overview.negativeAspects != null && this.htmlToString(this.$reviewStore.review.overview.negativeAspects).length >= 1
            },
            sectionInteractionAdded(){
                return this.$reviewStore.review.sectionReactions.filter(section => section.comments.length > 0).length > 0
            },
        }
    }
</script>

<style scoped lang="scss">

    .new-modal-title {
        .pill {
            margin-right: 8px;
            display: flex;
            padding: 6px 10px;
            align-items: center;
            border-radius: 20px;

            &.Empty {
                background-color: #F871711A;
                color: var(--rose40);
                font: var(--editor-ui-b3-medium);
                letter-spacing: 0.12px;
            }

            &.Incomplete {
                background-color: #FBBF241A;
                color: var(--zest40);
                font: var(--editor-ui-b3-medium);
                letter-spacing: 0.12px;
            }
        }
    }

    .dot {
        width: 6px;
        height: 6px;
        border-radius: 99px;
        background-color: var(--zest40);
        margin-left: 7.5px;
    }

    .tab-data {
        display: flex;
        height: 500px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        overflow: auto;
        overscroll-behavior: contain;

        ::-webkit-scrollbar-track {
            background-color: transparent; /* Makes the track transparent */
        }

        scrollbar-color: #888 transparent; /* Thumb color and track color */

        p {
            color: var(--asphalt10);
        }

        .overview {
            width: 100%;
            padding: 40px 24px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 40px;
            flex: 1 0 0;
            align-self: stretch;
        }

        .comments {
            display: flex;
            height: 500px;
            flex-direction: column;
            width: 100%;

            .comments-empty-container {
                padding: 36px 24px;
                height: 100%;
            }

            .selector-container {
                display: flex;
                padding: 24px;
                flex-direction: column;
                justify-content: center;
                gap: 10px;

                .text {
                    font: var(--editor-ui-b2-regular);
                    color:var(--asphalt40);
                }

                .string-value {
                    font: var(--editor-ui-b2-regular);
                    color:var(--asphalt40);
                }

                .string-option {
                    font: var(--editor-ui-b2-regular);
                    color:var(--asphalt40);

                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    svg {
                        width: 24px;
                        height: 24px;
                        color:var(--asphalt10);
                    }

                    &:hover {
                        cursor: pointer;
                        background-color: var(--asphalt80);
                    }
                }
            }

            .comments-list {
                overflow:auto;
                overscroll-behavior: contain;

                ::-webkit-scrollbar-track {
                    background-color: transparent; /* Makes the track transparent */
                }

                scrollbar-color: #888 transparent; /* Thumb color and track color */
            }
        }
    }
</style>