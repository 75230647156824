<template>
    <div :id="'section-' + section.index" class="ufo-sec ufo-sec--dividerline" :style="[ bgColor ]">
        <ToggleGrid />
        <div class="grid">
            <SectionBackgroundImage :section="section"/>
            <AnimationWrapper
                :animation-style="$store.getters.animationSettings && $store.getters.animationSettings.animationStyle !== 'NONE' ? 'FADE_IN' : null"
                :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
            >
                <template #animationContent>
                <div :class="['divider-line', dividerStyle.weight, dividerStyle.text_position, dividerStyle.text_alignment]">
                    <div v-if="dividerStyle.text_position !== 'no_text'"
                         class="text preview"
                         :style="[ bgColor ]"
                    >
                        <div :class="['ufo--section-text-container', section.title.style && section.title.style.textStyle ? section.title.style.textStyle : 'default--h2' ]"
                             :style="{color: section.title.style && !section.title.style.textStyle && section.title.style.fontColor ? section.title.style.fontColor : null }">
                            {{ fixText('title') }}
                        </div>
                    </div>
                    <div class="line preview"></div>
                </div>
                </template>
            </AnimationWrapper>
        </div>
    </div>
</template>

<script>

    import ToggleGrid from "../../../common/components/ToggleGrid.vue";
    import AnimationWrapper from "../../../common/components/AnimationWrapper.vue";
    import SectionBackgroundImage from "../../../sections/common/SectionBackgroundImage.vue";

    export default {
        name: "DividerLine",
        components: {SectionBackgroundImage, AnimationWrapper, ToggleGrid},
        props: ['section'],
        methods: {
            fixText(data) {
                return this.section[data].text.replaceAll('\n', '<br/>');
            }
        },
        computed: {
            portfolio() {
              return this.$store.state.view.portfolio
            },
            project() {
              return this.$store.state.view.project
            },
            dividerStyle() {
                return this.section.settings ?? this.portfolio.templateSettings.dividerLineSettings
            },
            bgColor() {
                if(this.section.backgroundColor) {
                    let bgColor = this.section.backgroundColor
                        ? this.section.backgroundColor.startsWith("#")
                            ? this.section.backgroundColor
                            : `#${this.section.backgroundColor}`
                        : null;

                    return bgColor ? {'background-color': bgColor} : null;
                } else {
                    return null
                }
            },
        }
    }
</script>