<template>
    <div class="review-wrapper" v-if="loaded">
        <ReviewNav :review-mode="true" :showOverviewModal="() => this.showOverviewModal = true"/>
        <slot></slot>
        <Overview :review-mode="true" />
        <Transition name="fade-in">
            <TourModal />
        </Transition>
        <!-- Modal for logging in the user -->
        <ReviewWelcomeModal :show="showWelcomeModal" :close="closeWelcomeModal" :get-review="getReview" />
        <ReviewLoginModalHandler :show="$reviewStore.showReviewLoginModal" :close="closeLoginModal" :getReview="getReview" />
        <OverviewModal :show="showOverviewModal" :close="closeOverviewModal" :openSuccessModal="openSuccessModal"/>
        <OverviewSuccessModal :show="showSuccessModal" :close="closeSuccessModal"/>
    </div>
</template>

<script>
    import ReviewNav from "./components/ReviewNavigation.vue";
    import Overview from "./components/Overview.vue";
    import TourModal from "./modals/TourModal.vue";
    import ApiHelper from "../../api/ApiHelper.js";
    import {ReviewLoginModalStateEnum, ReviewTypeEnum} from "../../common/enums.js";
    import OverviewModal from "./modals/OverviewModal.vue";
    import UsersAPI from "../../api/users/UserAPI.js";
    import OverviewSuccessModal from "./modals/OverviewSuccessModal.vue";
    import ReviewWelcomeModal from "./modals/ReviewWelcomeModal.vue";
    import ReviewLoginModalHandler from "@/scripts/view/review/modals/ReviewLoginModalHandler.vue";

    export default {
        name: "ReviewMode",
        components: {
            ReviewLoginModalHandler,
            ReviewWelcomeModal,
            OverviewSuccessModal,
            OverviewModal,
            TourModal,
            Overview,
            ReviewNav
        },
        data() {
            return {
                showLoginModal:false,
                showWelcomeModal:false,
                showSuccessModal:false,
                loaded:false,
                tourDisabled:false
            }
        },
        mounted() {
            this.checkForReviewer();
            this.isMobileDevice();
        },
        methods: {
            async checkForReviewer() {
                try {
                    //Check if we have a user, if yes we proceed to the next spte
                    let response = await UsersAPI.getMyUser();
                    this.$store.state.user = response.data.user;
                    this.loaded = true;
                    this.showWelcomeModal = true;
                } catch (e) {
                    //There wasn't a uxFolio user so we have to check for guest user
                    this.handleReviewerCheckFailure();
                }
            },
            openSuccessModal() {
                this.showSuccessModal = true;
            },
            closeSuccessModal() {
                this.showSuccessModal = false;
            },
            handleReviewerCheckFailure() {
                let reviewer = this.$cookies.get('reviewer');
                if (reviewer) {
                    this.initiateReviewForCookieReviewer(reviewer);
                } else {
                    const { followup } = this.$route.query;
                    //If user arrives with a followup query parameter it means they are already a user, but cookie is missing, so we ahve to log them in.
                    if(followup) {
                        this.$reviewStore.showReviewLoginModal = true
                    }else {
                        this.showWelcomePromt();
                    }
                }
            },
            async initiateReviewForCookieReviewer(reviewer) {
                try {
                    let contentId = this.$reviewStore.reviewType === ReviewTypeEnum.PORTFOLIO ? this.$store.state.view.portfolio._id : this.$store.state.view.project._id;
                    let encodedEmail = encodeURIComponent(reviewer.email);
                    let reviewerResponse = await ApiHelper.get(`/api/review/reviewer?email=${encodedEmail}&contentId=${contentId}`);

                    this.$cookies.set('reviewTour', JSON.stringify({reviewTourDone: true}));
                    this.$reviewStore.showTour = false;

                    if(reviewerResponse.data.review) {
                        let review = {...reviewerResponse.data.review, 'reviewerDetails': reviewerResponse.data.reviewer }
                        this.$store.commit('review/updateReview', review);
                        this.$reviewStore.reviews = [review];
                    }
                    else {
                        this.$reviewStore.reviewLoginModalState = ReviewLoginModalStateEnum.EXISTING_USER;
                        this.showWelcomeModal = true;
                    }
                    this.loaded = true;

                } catch (e) {
                    //Fallback, something was bad in the cookie, so we delete it and mount the initial page
                    this.$cookies.remove('reviewer');
                    this.loaded = true;
                }
            },
            showWelcomePromt() {
                this.loaded = true;
                this.showWelcomeModal = true;
            },
            closeLoginModal() {
                this.$reviewStore.showReviewLoginModal = false;
            },
            closeWelcomeModal() {
                this.showWelcomeModal = false;
            },
            closeOverviewModal() {
                this.showOverviewModal = false;
            },
            async getReview() {
                try {
                    let reviewer = this.$reviewStore.review.reviewerDetails ? this.$reviewStore.review.reviewerDetails.email : this.$store.state.user.email;
                    let contentId = this.$reviewStore.reviewType === ReviewTypeEnum.PORTFOLIO ? this.$store.state.view.portfolio._id : this.$store.state.view.project._id;

                    let reviewObject = {
                        reviewType: this.$reviewStore.reviewType,
                        reviewer:reviewer,
                        ownerId: this.$store.state.view.portfolio.ownerId,
                        contentId: contentId
                    }

                    let response = await ApiHelper.post("/api/review", reviewObject);
                    let review = {...response.data, 'reviewerDetails': this.$reviewStore.review.reviewerDetails }
                    this.$store.commit('review/updateReview', review);
                    this.$reviewStore.reviews = [review];
                    this.loaded = true;

                }
                catch(err) {
                    console.log('Error while fetching reviews', err);
                }
            },
            isMobileDevice() {
                if (((typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1) || window.innerWidth < 640 ) && !localStorage.getItem('uxfolioMobileNotificationProfile')) {
                    this.$store.state.alertModalMessage = {
                        type: "mobile-device",
                        title: "Please, switch to a desktop device",
                        message: "Review mode is optimized for desktop use.",
                    };
                    this.$store.state.activeModal = "AlertModal";
                }
            },
        },
        computed: {
            showOverviewModal: {
                get() {
                    return this.$reviewStore.showOverviewModal;
                },
                set(newValue) {
                    this.$reviewStore.showOverviewModal = newValue;
                }
            }
        }
    }
</script>

<style global lang="scss">
    .review-wrapper::-webkit-scrollbar-track {
        background-color: transparent; /* Makes the track transparent */
    }
    .review-wrapper {
        scrollbar-color: #888 transparent; /* Thumb color and track color */
    }
</style>